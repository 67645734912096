@font-face {
  font-family: 'FontDIN';
  src: url(../../static/media/DIN-Bold.62d54523.woff) format('woff'), url(../../static/media/DIN-Bold.218493c6.ttf) format('truetype'), url(../../static/media/DIN-Bold.6a92895e.svg#fontawesomeregular) format('svg');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #F7F7F7;
}

.root,
.page {
  display: -webkit-flex;
  flex-direction: column;
  height: 100%;
}

@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
  body {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}

.border {
  border-bottom: 0.013333rem solid #F0F0F0;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .border {
    border-bottom: 0.006667rem solid #F0F0F0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3) {
  .border {
    border-bottom: 0.004444rem solid #F0F0F0;
  }
}

selector::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.a-btn {
  width: 9.146667rem;
  height: 1.306667rem;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 0.653333rem;
  text-align: center;
  background: #DADBDD;
  margin: 0.64rem auto 0;
}

.a-btn span {
  display: block;
  font-size: 0.48rem;
  color: #fff;
}
.index-mmqb {
  position: relative;
  height: 100%;
  background: #F7F9FC;
  box-sizing: border-box;
  padding-bottom: 0.466667rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.index-mmqb .mmqb-top {
  width: 10rem;
  height: 4.346667rem;
  background: url(../../static/media/bgindex.64359375.png) center no-repeat;
  background-size: 10rem 4.346667rem;
}

.index-mmqb .mmqb-main {
  margin: 0 0.4rem;
  height: 6.4rem;
  border-radius: 0.16rem;
  box-shadow: 0 0.04rem 0.213333rem 0 #DFDEE3;
  margin-bottom: 0.346667rem;
}

.index-mmqb .mmqb-main .mmqb-card {
  height: 5.333333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content {
  top: 2.106667rem;
  position: absolute;
  width: 9.2rem;
  background: #FFFFFF;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title {
  margin: 0.506667rem 0.946667rem 0.173333rem;
  width: 7.306667rem;
  height: 3.84rem;
  background: url(../../static/media/card-bg.5b9cfef5.png) center no-repeat;
  background-size: 7.306667rem 3.84rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .title-borrow {
  text-align: center;
  padding-top: 2.173333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .title-borrow .borrow-text {
  height: 0.44rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #1E1E1E;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .title-borrow .borrow-money {
  height: 0.906667rem;
  font-size: 0.746667rem;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.906667rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc {
  padding-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.44rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #1E1E1E;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc span {
  flex: 1 1;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc span:nth-child(2) {
  text-align: right;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc-text {
  padding: 0.266667rem 0;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #75767C;
  line-height: 0.533333rem;
  text-shadow: 0 0.04rem 0.213333rem #DFDEE3;
  text-align: center;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-btn-footer {
  padding-top: 1.066667rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-btn-footer .card-btn {
  margin: 0.4rem 1.08rem;
  width: 7.04rem;
  height: 1.173333rem;
  background: linear-gradient(137deg, #1F5BF9 0%, #1D53E7 100%);
  box-shadow: 0 0.053333rem 0.24rem 0 #80A1EC;
  border-radius: 0.586667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.666667rem;
  letter-spacing: 0.04rem;
  line-height: 1.173333rem;
  text-align: center;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-btn-footer .card-btn-dis {
  margin: 0.4rem 1.08rem;
  width: 7.04rem;
  height: 1.173333rem;
  background: #BBBBBB;
  border-radius: 0.586667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.666667rem;
  letter-spacing: 0.04rem;
  line-height: 1.173333rem;
  text-align: center;
}

.index-mmqb .mmqb-main .card-notice {
  height: 1.066667rem;
  background: #FFFFFF;
  box-shadow: 0 0.04rem 0.213333rem 0 #DFDEE3;
}

.index-mmqb .mmqb-main .card-notice .awj-record {
  display: flex;
}

.index-mmqb .mmqb-main .card-notice .awj-record .record-img {
  margin: 0.333333rem 0.533333rem 0.173333rem 1.08rem;
  width: 0.426667rem;
  height: 0.36rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAYAAAAdx42aAAAAAXNSR0IArs4c6QAAAv9JREFUSEvtl19I01EUx7/n93M63fpHG4FERdGT9Gf7LR/KHjQQeumhB4tCSh3DalJEL72E0EO9RA8b6rYIkwxd+VBRD1GQPRTh7+cqBMuEigITXVk6/yy9J6Yz1trmVqk9dB8vl/P93HPOPedcwjwsi7MzXwqLagIZVa9yMpUE/U19xaEWEcjJ4L0AdACuaV7bwXkFWHf4jd6kGzzARE4Aljix+QOwVHWulSRxBAQ7gJU/hBlDIEgAlqbyANcii2oxmXEIFIdaDEINmPcAJMfcuIvA7vBY9lVd7mQ7wEoygLFW86Ep4uOG3InStAA2lz836PTfykHsBKggRnSSQLcgSS61wfJwdl9xaGoygFCruZKZfdNeIjxICaA41A0MOkbMFSAsjxEeBEk+SdbVd9Rteh+fZMkAQtdNdhbwANMh6teRKEkAwGSt7iwlwTUM7KaZw9FFGktwfxr/2vK2sXg8WXYnAmD/6twRMd5FhPVE6BOCdy3ZH+wmxaG9jhgSTOcCPutlxaEGAGyNEQ2DuG1KCPczX+HjdJ5tMg+M+VesESw3ywS7vmzwVcQWKQ6Vo0ZPaV7bBcWhDgFYFnERgxsI5NG8tr50hNPJgXg7qQDOal7bmUyE/wOk8gD7kT3KptOxHhXMwQULAfvNxhDzcFxIexYOoGmVIZQj3sUCEHHvggEkS+Z/GqCfQfVylt7TUVfwMZPnmKoXJKoDPTOVkM8HfNsSVEKEwWgTkuQKeKxP0gFJBTDcai4zdg/coFqI6Ur4q8EUvYChgWTXlxxjS69r40QmvSBydqTVdBHACTA3Gl4GqyIQc3ZDEI5CoPKnbsgYICKfLLLqn17a8iGdbsh+5I7CdI8ZRTM3p6a8soGKP54HGHRT5ilXh6+wfa5SHKkFI+C7xNgZhbiSFkDsDa12rYRkdiaYiF6wRG4S3AzQo2QDCU/Xg6k7AO+QJCrPGGAWJtlMyMDn6AwR6agJh1K+nZ8XGp3YbtwXvP/bALMgizYVJ3oBi/IvSASSyc/oO0VK7co2H+OjAAAAAElFTkSuQmCC) 0 0 no-repeat;
  background-size: 0.426667rem 0.36rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .record-bar {
  margin-top: 0.32rem;
  width: 78%;
  height: 0.44rem;
  background: #F7F9FC;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #949498;
  line-height: 0.44rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .record-bar .am-notice-bar-content {
  margin: 0;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap {
  width: 6.666667rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news {
  position: relative;
  top: 0;
  color: #bebebe;
  height: 1.066667rem;
  font-size: 0.346667rem;
  line-height: 1.066667rem;
  flex-grow: 1;
  overflow: hidden;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news ul {
  position: absolute;
  top: -0.373333rem;
  left: 0;
  list-style: none;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news ul .consulation-news-item {
  width: 100%;
  line-height: 1.066667rem;
  height: 1.066667rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news .anim {
  padding: 0;
  transition: all 0.6s;
  margin-top: -0.733333rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news .animNull {
  padding: 0;
}

.index-mmqb .awj-bill {
  margin-bottom: 0.266667rem;
}

.index-mmqb .awj-bill .awj-reimbursement {
  margin: 0 0.4rem;
  width: 9.2rem;
  height: 2.346667rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.093333rem 0 #DFDEE3;
  border-radius: 0.24rem;
  display: flex;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 5.333333rem;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-time {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(1) {
  color: #FE4040;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(2) {
  margin-left: 0.08rem;
  color: #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-number {
  margin-top: 0.226667rem;
  height: 0.786667rem;
  font-size: 0.56rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.786667rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-r {
  margin-right: 0.4rem;
  margin-top: 0.906667rem;
  margin-left: 1.333333rem;
  display: flex;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-r .r-text {
  display: inline-block;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-r .r-icon {
  display: inline-block;
  margin-left: 0.133333rem;
  width: 0.373333rem;
  height: 0.506667rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAABgCAYAAABbjPFwAAACZUlEQVR4Xu2awWoUQRRFT4ybaAxm40KCLhMx6Cr5/y/QD9BAAoFAjEsXESXhwTTMIpOu17fqVQ28hlkMXfX6nnuruqub2mHLj50t108C9E4wE8gERAdyCIkGyt0zAdlCsUAmIBood88EZAvFApmAaKDcPROQLRQLZAKigXL3TEC2UCywJIGXwGvgAfgD/Bc1SN29AO+Aj8Du6qom/hL4JakQOnsADoHjDde6BuwXfngAToH9ZxR2gfAAnAMvZiwOh/AAfAX2CsZIKIQH4AiwX8kRBuEBsOHzCXhTQrCa1M0ntgfAdNsz4GRmMq/zNU/CCzAcxBKACcKeCd2H01KAYSAUgCEgVIDuEDUAukLUAjAIW6HaLTZ0YtcE6AJRGyAcogVAKEQrgDCIlgAhEK0BlkDY+/VF4Yo3bKuB9xZbDBGRwGSmF+Ib8HcuiUiAaTjZS9FzHwcmzVfAzYgApU/r4QBsCNk7xMGcq6vz34H7ubZRQ8gr/g74OSfezkcAeMX/Bn6UiI8A8Ip3fwRomUBz8S0TCBHfCiBMfAuAUPG1AcLF1wToIr4WQDfxNQC6ilcBuotXAIYQvxRgGPFLAIYS7wWwdZO9TZWu590Ls9IV6Ho7z2LuPfCh8CIh4r0JfAFeFQCEifcCnK3tkdjEESreC/B55tN5uHgvwNvV9/+n3O8i3gtg7Z/abmOfP24L5kaTJp670CRg2vBk/23D078mygqLLgEoLB3TLAFifN58lUwgExAdyCEkGih3zwRkC8UCmYBooNw9E5AtFAtkAqKBcvdMQLZQLJAJiAbK3TMB2UKxwNYn8Aj+kWdhpTd94QAAAABJRU5ErkJggg==) 0 0 no-repeat;
  background-size: 0.373333rem 0.506667rem;
}

.index-mmqb .awj-bills {
  margin: 0 0.4rem 0.266667rem;
  display: flex;
}

.index-mmqb .awj-bills .awj-reimbursement {
  width: 9.2rem;
  height: 2.346667rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.093333rem 0 #DFDEE3;
  border-radius: 0.24rem;
  display: flex;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 5.333333rem;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-time {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(1) {
  color: #FE4040;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(2) {
  margin-left: 0.08rem;
  color: #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-number {
  margin-top: 0.226667rem;
  height: 0.786667rem;
  font-size: 0.56rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.786667rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-r {
  margin-right: 0.4rem;
  margin-top: 0.906667rem;
  margin-left: 1.333333rem;
  display: flex;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-r .r-text {
  display: inline-block;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-r .r-icon {
  display: inline-block;
  margin-left: 0.133333rem;
  width: 0.373333rem;
  height: 0.506667rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAABgCAYAAABbjPFwAAACZUlEQVR4Xu2awWoUQRRFT4ybaAxm40KCLhMx6Cr5/y/QD9BAAoFAjEsXESXhwTTMIpOu17fqVQ28hlkMXfX6nnuruqub2mHLj50t108C9E4wE8gERAdyCIkGyt0zAdlCsUAmIBood88EZAvFApmAaKDcPROQLRQLZAKigXL3TEC2UCywJIGXwGvgAfgD/Bc1SN29AO+Aj8Du6qom/hL4JakQOnsADoHjDde6BuwXfngAToH9ZxR2gfAAnAMvZiwOh/AAfAX2CsZIKIQH4AiwX8kRBuEBsOHzCXhTQrCa1M0ntgfAdNsz4GRmMq/zNU/CCzAcxBKACcKeCd2H01KAYSAUgCEgVIDuEDUAukLUAjAIW6HaLTZ0YtcE6AJRGyAcogVAKEQrgDCIlgAhEK0BlkDY+/VF4Yo3bKuB9xZbDBGRwGSmF+Ib8HcuiUiAaTjZS9FzHwcmzVfAzYgApU/r4QBsCNk7xMGcq6vz34H7ubZRQ8gr/g74OSfezkcAeMX/Bn6UiI8A8Ip3fwRomUBz8S0TCBHfCiBMfAuAUPG1AcLF1wToIr4WQDfxNQC6ilcBuotXAIYQvxRgGPFLAIYS7wWwdZO9TZWu590Ls9IV6Ho7z2LuPfCh8CIh4r0JfAFeFQCEifcCnK3tkdjEESreC/B55tN5uHgvwNvV9/+n3O8i3gtg7Z/abmOfP24L5kaTJp670CRg2vBk/23D078mygqLLgEoLB3TLAFifN58lUwgExAdyCEkGih3zwRkC8UCmYBooNw9E5AtFAtkAqKBcvdMQLZQLJAJiAbK3TMB2UKxwNYn8Aj+kWdhpTd94QAAAABJRU5ErkJggg==) 0 0 no-repeat;
  background-size: 0.373333rem 0.506667rem;
}

.index-mmqb .awj-bills .bill-box {
  width: 4.533333rem;
  height: 3.106667rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.093333rem 0 #DFDEE3;
  border-radius: 0.24rem;
}

.index-mmqb .awj-bills .bill-box .bill-b-top {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-top span:nth-child(1) {
  color: #FE4040;
}

.index-mmqb .awj-bills .bill-box .bill-b-top span:nth-child(2) {
  padding-left: 0.106667rem;
  color: #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-number {
  margin: 0.226667rem 0 0.226667rem 0.4rem;
  height: 0.786667rem;
  font-size: 0.56rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.786667rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-bottom {
  margin-left: 2.48rem;
  display: flex;
}

.index-mmqb .awj-bills .bill-box .bill-b-bottom span {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-bottom i {
  display: inline-block;
  width: 0.506667rem;
  height: 0.613333rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAuCAYAAABEbmvDAAAAAXNSR0IArs4c6QAABVtJREFUWEftmMlvHEUUxl8tXdU9SxZrjGO7HY/DYKSI5QLKDZD4C5D4F1AuHFAuHFBy4ZRciEAJYpM4ADcOII5IcEOCAwcQUrBJ7HjssQkex+PptZaHajwTOU5ke7xhJLdU6sP08uuv3nzvqyJwRA9yRLngGKzfmTlW7FixfhXo9/r/d40hYu8DtvoQdKoQQjrnvR7bKtaFctdtHhvfbQHAAfXGngG3BNsERQGA1ut1ylhIKF3q3GvtEBpTxzAMHVwPsHPei3o7AesAzcwAC4IltrbmsUqF0na7TQFOgLWI5bK1xhirlDLDw8MGANzYE9xOwNj0NLByeYlHkeBSxvzWrVsfW2tflVJerlarXxvjWym1zrJAF4tKR1Gkq9VqD87uRrntwJxazKlVKv3jZVkmtNZienr6V0QcctMlZfDu5GTtc2OMznNPcS5z389VpVJRG5TrG25HYI1Gw8uyQARBJqLI+s3m/Iurq6ufIuIpV2dCiBu12vkPtI4VpTRjjGVR5ABjFYah7k5rX3A7AeONBnCl7krEgg9gfEIwWF5unF9ZWblprR3swn0ZhueuCiETQvI0SVgGIDLfH1BhCH3D9QVWKBT8OLYBIVkBgPv3l1cm/l5uXEfEEQfHOf9uYmLyCucYEUISSmkaxzIX4nTeL9xOwFi9Dlzr+7JYVH6SJAWiSAEIBOBxf22tdWZxceGatabahftxZGT8bc+Tq1L6MaUijWPVN9x2YO53V/xc66YolXKJiAFkUFAUiqAhAG5lkiQD8/Nz71prnnZwlPJfxscn3qLUW16Hy9N2W2S12kAO8GBat/S5nYB1/plLS0ueUkoi+n6epwVKoSCIU44FiChTnZbr9dl3tNbPOzjG2O/Dw2NvlsunGgBZDACJECIbHBx0cB0r2cpGdgLmrnFwvNlsimYzl5Ri4MAohaIxukgIBAyZzIz2FxZmLuV5fmFdOfpXpXLm4unTlRlrkxgRE855FoZhx0p2DeYe3m1LHdUajQZ3tgGgfEqTAqXSgZUIIUUACBCtQI3e3MLsxSxLX+nC/VmrnX9Na2xLWYo4j9OhoaGOaoQQ1x0ee2zbxLtwnbbU6wBaax8gCJTKStbqsmReyRDj4HytUc7Pz7yRZenLXbCpyclnXvc8XGu3ITp5kiWVSiVztbYfYL1kwer1Oi+VSlJrHbRaaclntGzdyE3Zgi3Pzt2+pJR6oQs1OzIydunEieIdY3hLSmi3Wjyp1QZ6YK7W9qTYQ2Bal2Sp9DCYSvPBu/N3LiulnuvYBuVTI+HYFSHkohB09aDAtpxKq7LhuYW5a8as24Xneb+Njo5d9TzRJIStUcpa+z6V2xV/u90abzTq7xtjJrqt6eezoxPvUcoiQIypYGvWQntfi39DUHysXbRazafu3Vv8EBFH15USP1THzt0gnGQAJEOEyFqMOPfa+2oXG9XabLCrq8vPNpvLHwGsN3Ep5LfVsSc/s9RqQqizgwQRYs5FdFAG+0hLWphbuLDSWvkEAE46qEAGX4Rnx7+iyKwlVgGQ1IFZC/FBtaSOsfaaeM9Yp6amfkLEJ5z/BkHx5tjI+DfA0FLLtAbjrCBFJMlBNvFObfXyWC/23L79hyv2lwqFwvUwrH7fNWFDjVXgiZQQlR5G7HkAFgSBXM9j6NqPpJRyYzRFBKSUakpZTghNDysouh75ULQGMJJz7lFKmFviImaWsUAddrR+ZDEihPCUYpxSQiHLwCt55rAXI52guHn5lueeSy+MuNyCiP/F8q2XxY7kgrfXwI/OFsGGoHi0NlV6QelIbkPtdZ9rt/fvKFrv9uF7ue8YrF/1jqxi/wJixflcd6XbaQAAAABJRU5ErkJggg==) 0 0 no-repeat;
  background-size: 0.506667rem 0.613333rem;
}

.index-mmqb .awj-bills .bill-box:nth-child(1) {
  margin-right: 0.133333rem;
}

.index-mmqb .mmqb-footer {
  margin: 0.76rem 0.4rem 1.24rem;
  width: 9.2rem;
  height: 1.853333rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.16rem 0 #E1E1E1;
  border-radius: 0.16rem;
  display: flex;
}

.index-mmqb .mmqb-footer span:nth-child(1) {
  margin-left: 1.36rem;
  font-size: 0.453333rem;
  font-family: FontName;
  color: #1D54EA;
  line-height: 1.853333rem;
  text-align: center;
}

.index-mmqb .mmqb-footer span:nth-child(2) {
  display: block;
  margin-left: 0.933333rem;
  width: 2.186667rem;
  height: 1.746667rem;
  background: url(../../static/media/product-more.98adc532.png) center no-repeat;
  background-size: 2.186667rem 1.746667rem;
}

.banner {
  margin-top: 0.4rem;
  height: 2.213333rem;
  box-sizing: border-box;
  padding: 0 0.426667rem;
}

.banner .b-slide {
  width: 9.146667rem;
  height: 2.8rem;
}

.banner .b-slide .b-slide-box {
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  height: 2.213333rem;
}

.banner .b-slide .b-slide-box img {
  display: block;
  max-height: 2.213333rem;
  width: 100%;
  object-fit: cover;
}

.banner .swiper-pagination {
  height: 0.586667rem;
  bottom: 40;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

.banner .swiper-pagination-bullet-active {
  background: #5D5D5D;
}
