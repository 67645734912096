@font-face {
  font-family: 'FontDIN';
  src: url('./fonts/DIN-Bold.woff?v=4.5.0') format('woff'), url('./fonts/DIN-Bold.ttf?v=4.5.0') format('truetype'), url('./fonts/DIN-Bold.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #F7F7F7;
}

.root,
.page {
  display: -webkit-flex;
  flex-direction: column;
  height: 100%;
}

@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
  body {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}

.border {
  border-bottom: 0.013333rem solid #F0F0F0;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .border {
    border-bottom: 0.006667rem solid #F0F0F0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3) {
  .border {
    border-bottom: 0.004444rem solid #F0F0F0;
  }
}

selector::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.a-btn {
  width: 9.146667rem;
  height: 1.306667rem;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 0.653333rem;
  text-align: center;
  background: #DADBDD;
  margin: 0.64rem auto 0;
}

.a-btn span {
  display: block;
  font-size: 0.48rem;
  color: #fff;
}