.index-mmqb {
  position: relative;
  height: 100%;
  background: #F7F9FC;
  box-sizing: border-box;
  padding-bottom: 0.466667rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.index-mmqb .mmqb-top {
  width: 10rem;
  height: 4.346667rem;
  background: url("../../assets/imgs/zzd/index/bgindex.png") center no-repeat;
  background-size: 10rem 4.346667rem;
}

.index-mmqb .mmqb-main {
  margin: 0 0.4rem;
  height: 6.4rem;
  border-radius: 0.16rem;
  box-shadow: 0 0.04rem 0.213333rem 0 #DFDEE3;
  margin-bottom: 0.346667rem;
}

.index-mmqb .mmqb-main .mmqb-card {
  height: 5.333333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content {
  top: 2.106667rem;
  position: absolute;
  width: 9.2rem;
  background: #FFFFFF;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title {
  margin: 0.506667rem 0.946667rem 0.173333rem;
  width: 7.306667rem;
  height: 3.84rem;
  background: url("../../assets/imgs/zzd/index/card-bg.png") center no-repeat;
  background-size: 7.306667rem 3.84rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .title-borrow {
  text-align: center;
  padding-top: 2.173333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .title-borrow .borrow-text {
  height: 0.44rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #1E1E1E;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .title-borrow .borrow-money {
  height: 0.906667rem;
  font-size: 0.746667rem;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.906667rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc {
  padding-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.44rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #1E1E1E;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc span {
  flex: 1 1;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc span:nth-child(2) {
  text-align: right;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-title .card-desc-text {
  padding: 0.266667rem 0;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #75767C;
  line-height: 0.533333rem;
  text-shadow: 0 0.04rem 0.213333rem #DFDEE3;
  text-align: center;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-btn-footer {
  padding-top: 1.066667rem;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-btn-footer .card-btn {
  margin: 0.4rem 1.08rem;
  width: 7.04rem;
  height: 1.173333rem;
  background: linear-gradient(137deg, #1F5BF9 0%, #1D53E7 100%);
  box-shadow: 0 0.053333rem 0.24rem 0 #80A1EC;
  border-radius: 0.586667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.666667rem;
  letter-spacing: 0.04rem;
  line-height: 1.173333rem;
  text-align: center;
}

.index-mmqb .mmqb-main .mmqb-card .main-card-content .card-btn-footer .card-btn-dis {
  margin: 0.4rem 1.08rem;
  width: 7.04rem;
  height: 1.173333rem;
  background: #BBBBBB;
  border-radius: 0.586667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.666667rem;
  letter-spacing: 0.04rem;
  line-height: 1.173333rem;
  text-align: center;
}

.index-mmqb .mmqb-main .card-notice {
  height: 1.066667rem;
  background: #FFFFFF;
  box-shadow: 0 0.04rem 0.213333rem 0 #DFDEE3;
}

.index-mmqb .mmqb-main .card-notice .awj-record {
  display: flex;
}

.index-mmqb .mmqb-main .card-notice .awj-record .record-img {
  margin: 0.333333rem 0.533333rem 0.173333rem 1.08rem;
  width: 0.426667rem;
  height: 0.36rem;
  background: url("../../assets/imgs/zzd/index/notice.png") 0 0 no-repeat;
  background-size: 0.426667rem 0.36rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .record-bar {
  margin-top: 0.32rem;
  width: 78%;
  height: 0.44rem;
  background: #F7F9FC;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #949498;
  line-height: 0.44rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .record-bar .am-notice-bar-content {
  margin: 0;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap {
  width: 6.666667rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news {
  position: relative;
  top: 0;
  color: #bebebe;
  height: 1.066667rem;
  font-size: 0.346667rem;
  line-height: 1.066667rem;
  flex-grow: 1;
  overflow: hidden;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news ul {
  position: absolute;
  top: -0.373333rem;
  left: 0;
  list-style: none;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news ul .consulation-news-item {
  width: 100%;
  line-height: 1.066667rem;
  height: 1.066667rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news .anim {
  padding: 0;
  transition: all 0.6s;
  margin-top: -0.733333rem;
}

.index-mmqb .mmqb-main .card-notice .awj-record .cosultation-wrap .cosulation-news .animNull {
  padding: 0;
}

.index-mmqb .awj-bill {
  margin-bottom: 0.266667rem;
}

.index-mmqb .awj-bill .awj-reimbursement {
  margin: 0 0.4rem;
  width: 9.2rem;
  height: 2.346667rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.093333rem 0 #DFDEE3;
  border-radius: 0.24rem;
  display: flex;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 5.333333rem;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-time {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(1) {
  color: #FE4040;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(2) {
  margin-left: 0.08rem;
  color: #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-l .l-number {
  margin-top: 0.226667rem;
  height: 0.786667rem;
  font-size: 0.56rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.786667rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-r {
  margin-right: 0.4rem;
  margin-top: 0.906667rem;
  margin-left: 1.333333rem;
  display: flex;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-r .r-text {
  display: inline-block;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bill .awj-reimbursement .awj-reimbursement-r .r-icon {
  display: inline-block;
  margin-left: 0.133333rem;
  width: 0.373333rem;
  height: 0.506667rem;
  background: url("../../assets/imgs/zzd/common/i-a-arrow.png") 0 0 no-repeat;
  background-size: 0.373333rem 0.506667rem;
}

.index-mmqb .awj-bills {
  margin: 0 0.4rem 0.266667rem;
  display: flex;
}

.index-mmqb .awj-bills .awj-reimbursement {
  width: 9.2rem;
  height: 2.346667rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.093333rem 0 #DFDEE3;
  border-radius: 0.24rem;
  display: flex;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 5.333333rem;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-time {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(1) {
  color: #FE4040;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-time span:nth-child(2) {
  margin-left: 0.08rem;
  color: #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-l .l-number {
  margin-top: 0.226667rem;
  height: 0.786667rem;
  font-size: 0.56rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.786667rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-r {
  margin-right: 0.4rem;
  margin-top: 0.906667rem;
  margin-left: 1.333333rem;
  display: flex;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-r .r-text {
  display: inline-block;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .awj-reimbursement .awj-reimbursement-r .r-icon {
  display: inline-block;
  margin-left: 0.133333rem;
  width: 0.373333rem;
  height: 0.506667rem;
  background: url("../../assets/imgs/zzd/common/i-a-arrow.png") 0 0 no-repeat;
  background-size: 0.373333rem 0.506667rem;
}

.index-mmqb .awj-bills .bill-box {
  width: 4.533333rem;
  height: 3.106667rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.093333rem 0 #DFDEE3;
  border-radius: 0.24rem;
}

.index-mmqb .awj-bills .bill-box .bill-b-top {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-top span:nth-child(1) {
  color: #FE4040;
}

.index-mmqb .awj-bills .bill-box .bill-b-top span:nth-child(2) {
  padding-left: 0.106667rem;
  color: #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-number {
  margin: 0.226667rem 0 0.226667rem 0.4rem;
  height: 0.786667rem;
  font-size: 0.56rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.786667rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-bottom {
  margin-left: 2.48rem;
  display: flex;
}

.index-mmqb .awj-bills .bill-box .bill-b-bottom span {
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 0.533333rem;
  text-shadow: 0 0.026667rem 0.093333rem #DFDEE3;
}

.index-mmqb .awj-bills .bill-box .bill-b-bottom i {
  display: inline-block;
  width: 0.506667rem;
  height: 0.613333rem;
  background: url("../../assets/imgs/zzd/common/index-r-icon.png") 0 0 no-repeat;
  background-size: 0.506667rem 0.613333rem;
}

.index-mmqb .awj-bills .bill-box:nth-child(1) {
  margin-right: 0.133333rem;
}

.index-mmqb .mmqb-footer {
  margin: 0.76rem 0.4rem 1.24rem;
  width: 9.2rem;
  height: 1.853333rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.16rem 0 #E1E1E1;
  border-radius: 0.16rem;
  display: flex;
}

.index-mmqb .mmqb-footer span:nth-child(1) {
  margin-left: 1.36rem;
  font-size: 0.453333rem;
  font-family: FontName;
  color: #1D54EA;
  line-height: 1.853333rem;
  text-align: center;
}

.index-mmqb .mmqb-footer span:nth-child(2) {
  display: block;
  margin-left: 0.933333rem;
  width: 2.186667rem;
  height: 1.746667rem;
  background: url("../../assets/imgs/zzd/index/product-more.png") center no-repeat;
  background-size: 2.186667rem 1.746667rem;
}

.banner {
  margin-top: 0.4rem;
  height: 2.213333rem;
  box-sizing: border-box;
  padding: 0 0.426667rem;
}

.banner .b-slide {
  width: 9.146667rem;
  height: 2.8rem;
}

.banner .b-slide .b-slide-box {
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  height: 2.213333rem;
}

.banner .b-slide .b-slide-box img {
  display: block;
  max-height: 2.213333rem;
  width: 100%;
  object-fit: cover;
}

.banner .swiper-pagination {
  height: 0.586667rem;
  bottom: 40;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

.banner .swiper-pagination-bullet-active {
  background: #5D5D5D;
}